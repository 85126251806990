<!--
    Created by 程雨喵'mac on 2023/12/28.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：设备类型管理 - 新增/编辑
    功能概述：新增、编辑、启用、禁用
-->
<style lang="less">

</style>

<template>
  <yg-modal
    width="600"
    :title="model.id ? '编辑' : '新增'"
    sure-text="保存"
    :sure-button-disabled="buttonDisabled"
    :sure-button-disabled-text="buttonDisabledMsg"
    @on-sure="onSure"
    @on-close="onClose"
  >
    <div
      slot="content"
      style="min-height: 200px"
    >
      <div class="device-type-submit-content">
        <Form>
          <FormItem
            v-for="(item, index) in formItems"
            :key="index"
            :label="item.label"
            :label-width="item.labelWidth"
            :required="item.isRequired"
          >
            <Select
              v-if="item.tagName === 'Select'"
              v-model="item.value"
              :clearable="item.clearable"
              :disabled="item.disabled"
              style="width: 100%"
              @input="checkSubmit"
            >
              <Option
                v-for="(op, p) in item.data"
                :key="p + '-op'"
                :value="op.value"
              >
                {{ op.label }}
              </Option>
            </Select>
            <Input
              v-if="item.tagName === 'Input'"
              v-model="model[item.key]"
              type="text"
              :maxlength="item.maxLength"
              :placeholder="'请输入' + item.label"
              :disabled="item.disabled"
              @input="checkSubmit"
            />
            <yg-global-select
              v-if="item.tagName === 'YgGlobalSelect'"
              v-model="model[item.key]"
              :select-type="item.tagKey"
              @input="checkSubmit"
            />
          </FormItem>
        </Form>
      </div>
    </div>
  </yg-modal>
</template>

<script>
export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      buttonDisabled: true,
      buttonDisabledMsg: '',
      // 表单数据
      formItems: [
        {
          label: '生产厂商',
          labelWidth: 135,
          key: 'manufacturers',
          tagName: 'YgGlobalSelect',
          tagKey: 'factoryType',
          disabled: false,
          clearable: true,
          isRequired: true,
          data: []
        },
        {
          label: '设备类别',
          labelWidth: 135,
          key: 'deviceCategory',
          tagName: 'YgGlobalSelect',
          tagKey: 'deviceType',
          disabled: false,
          clearable: true,
          isRequired: true,
          data: []
        },
        {
          label: '端口类别',
          labelWidth: 135,
          key: 'portCategory',
          tagName: 'YgGlobalSelect',
          tagKey: 'chargingPileType',
          disabled: false,
          clearable: true,
          isRequired: true,
          data: []
        },
        {
          label: '设备型号编码',
          labelWidth: 135,
          key: 'deviceModel',
          tagName: 'Input',
          disabled: false,
          isRequired: true,
          maxLength: '20'
        },
        {
          label: '设备型号名称',
          labelWidth: 135,
          key: 'deviceName',
          tagName: 'Input',
          disabled: false,
          isRequired: true,
          maxLength: '20'
        },
        {
          label: '物联网参数',
          labelWidth: 135,
          key: 'iotInfo',
          tagName: 'Input',
          disabled: false,
          isRequired: false,
          maxLength: '20'
        },
        {
          label: '厂商型号代码',
          labelWidth: 135,
          key: 'modeCode',
          tagName: 'Input',
          disabled: false,
          isRequired: false,
          maxLength: '20'
        }
      ]
    };
  },
  created () {
    console.log(this.model);
  },
  methods: {
    onSure () {
      console.log(this.model);
      const res = this.model.checkValidSubmit();
      if (!res.success) {
        this.$Message.warning(res.msg);
      } else {
        this.$emit('on-sure', res.params);
      }
    },
    onClose () {
      this.$emit('on-close');
    },
    checkSubmit () {
      const item = this.formItems.find(v => v.isRequired && !this.model[v.key]);
      this.buttonDisabled = !!item;
      this.buttonDisabledMsg = item ? (item.label + '不可为空') : '';
    }
  }
};
</script>
