import { isValidString1 } from '@/libs/regularUtils';

export class DeviceTypeDataModel extends BaseModel {
  constructor () {
    super();
    // 设备类别ID
    this.id = '';
    // 生产厂商
    this.manufacturers = '';
    this.manufacturersName = '';
    // 设备类别
    this.deviceCategory = '';
    // 设备类别名称
    this.deviceCategoryName = '';
    // 端口类别
    this.portCategory = '';
    // 端口类别名称
    this.portCategoryName = '';
    // 设备型号编码
    this.deviceModel = '';
    // 设备型号名称
    this.deviceName = '';
    // 物联网参数
    this.iotInfo = '';
    // 厂商型号代码
    this.modeCode = '';
    // 状态
    this.status = '';
    // 状态描述
    this.statusDesc = '';
    // 前端自有
    this.h5StatusSwitch = false;
  }

  initData (resData) {
    super.initData(resData);
    this.deviceCategoryName = ((resData.deviceTypeList || []).find(v => v.value === resData.deviceCategory) || {}).label;
    this.portCategoryName = ((resData.chargingPileTypeList || []).find(v => v.value === resData.portCategory) || {}).label;
    this.manufacturersName = ((resData.factoryTypeList || []).find(v => v.value === resData.manufacturers) || {}).label;
    this.h5StatusSwitch = !!this.status;
    return this;
  }

  static createFakeDataList (len = 30) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const statusDesc = ['启用', '禁用'];
      const dic = {
        manufacturers: BaseModel.getFakeTitle(Math.ceil(Math.random() * 50)),
        deviceTypeId: '000101010' + i,
        statusDesc: statusDesc[i % 2],
        status: i % 2,
        deviceModel: 'UHFDHfk00002' + i
      };
      list.push(dic);
    }
    return list;
  }

  /**
   * 数据具体校验-新增/编辑设备类型
   * @returns {*}
   */
  checkValidSubmit () {
    // 专项校验
    if (this.deviceModel.length < 4 || this.deviceModel.length > 20) {
      return BaseModel.getValidRes(false, '请输入长度为4-20位的设备型号编码');
    } else if (!isValidString1(this.deviceModel)) {
      return BaseModel.getValidRes(false, '请输入有效字符');
    } else if (this.deviceName.length < 4 || this.deviceName.length > 20) {
      return BaseModel.getValidRes(false, '请输入长度为4-20位的设备型号名称');
    } else if (!isValidString1(this.deviceName)) {
      return BaseModel.getValidRes(false, '请输入有效字符');
    }

    if (this.iotInfo) {
      if (this.iotInfo.length < 4 || this.iotInfo.length > 20) {
        return BaseModel.getValidRes(false, '请输入长度为4-20位的物联网参数');
      } else if (!isValidString1(this.iotInfo)) {
        return BaseModel.getValidRes(false, '请输入有效字符');
      }
    }

    if (this.modeCode) {
      if (this.modeCode.length < 4 || this.modeCode.length > 20) {
        return BaseModel.getValidRes(false, '请输入长度为4-20位的厂商型号代码');
      } else if (!isValidString1(this.modeCode)) {
        return BaseModel.getValidRes(false, '请输入有效字符');
      }
    }

    const params = {
      manufacturers: this.manufacturers,
      deviceCategory: this.deviceCategory,
      portCategory: this.portCategory,
      deviceModel: this.deviceModel,
      deviceModelName: this.deviceName,
      iotInfo: this.iotInfo,
      modelCode: this.modeCode
    };

    if (this.id) {
      params.deviceTypeId = this.id;
    }

    return BaseModel.getValidRes(true, '', params);
  }

  static createAdditionModel () {
    return new this();
  }
}
