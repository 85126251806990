/**
 * @date:2024/01/24
 * @author 王丽莎
 * @copyright 南京云柜<yun-gui.com>
 * 基础配置相关
 */

import BaseApi from '@/request/base/BaseApi';

class BasicsApiSet {
  constructor () {
    // 设备类型相关
    this.getListApi = BaseApi.createModel('设备类型信息列表', '/opmp/deviceType/list').setMethod();
    this.addDeviceTypeApi = BaseApi.createModel('新增设备类型', '/opmp/deviceType/add');
    this.editDeviceTypeApi = BaseApi.createModel('编辑设备类型', '/opmp/deviceType/edit');
    this.changeStatusApi = BaseApi.createModel('启用禁用设备类型', '/opmp/deviceType/changeStatus').setMethod(2);
    this.getInfoApi = BaseApi.createModel('获取设备类型信息', '/opmp/deviceType/info').setMethod(0, false);
  }
}
export default new BasicsApiSet();
